import React,{useEffect, useState} from "react";
import alanBtn from "@alan-ai/alan-sdk-web";
import NewsCards from './components/NewsCards/NewsCards'

const alanKey = "d841ae9c9c40639583417a5c8d99572a2e956eca572e1d8b807a3e2338fdd0dc/stage"

const App =() =>  {

  const [newsArticles, setNewsArticles] = useState([])

  
  useEffect(()=>{
    alanBtn({
      key: alanKey,
      onCommand: ({command, articles}) => {
        if(command==='newsHeadlines'){
          setNewsArticles(articles)
          console.log(articles)
        }
      }
    })
  }, [])
  
  return (
    <div className="App">
      <h1>Alan AI news website</h1>
      {newsArticles}
      
      <NewsCards articles={newsArticles} />
    </div>
  );
}

export default App;
